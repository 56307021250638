
import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import hodl from '../../pictures/CGVHodlVideo.gif'; 
import Gecko from "../../pictures/geckoterminal.png";

export default function GeckoTer() {
  return (
    <Card sx={{ width: 400 }}>
      <div>
        <Typography level="title-lg">CGV is verified at        {" "}Gecko Terminal</Typography>
        <Typography level="body-sm">Most comprehensive real-time DEX tracker</Typography>
        <IconButton
          aria-label="bookmark CGV"
          variant="soft"
          color="neutral"
          size="sm"
          sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
        >
          <BookmarkAdd />
        </IconButton>
      </div>
      <AspectRatio minHeight="120px" maxHeight="200px">
        <img
          src={hodl}
          // srcSet={airDrop}
          loading="lazy"
          alt=""
        />
      </AspectRatio>
      <CardContent orientation="horizontal">
        <div>
          <Typography level="body-xs">Current price:</Typography>
          <Typography fontSize="lg" fontWeight="lg">
            $0.0082
          </Typography>
        </div>
        <Button
          variant="solid"
          size="md"
          color="primary"
          aria-label="Explore CGV"
          sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
          onClick={() =>
            window.open(
              "https://www.geckoterminal.com/ton/pools/EQD0zSg8MWJSahKo-E_wPIOGUltoWdKE9wwLtZdfSRD8DoPq",
              "_blank"
            )
          }
        >
              <img alt="Grecko" className="logoCrypto1" src={Gecko} />
        </Button>
      </CardContent>
    </Card>
  );
}