import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const stepsModal = [
  {
    label: <b style={{ color: "orange" }}>JOIN FIRST IDO ROUND</b>,
    description: (
      <i>
        Participating in the CGV IDO first round allows investors to obtain CGV
        tokens at a preferential rate, potentially offering them an advantage as
        the project evolves.
      </i>
    ),
  },
  {
    label: <b style={{ color: "orange" }}>8% BONUS</b>,
    description: (
      <i>
        When sending TON tokens and aiming to receive an 8% bonus through a
        referral program, ensure to include a wallet referral comment.
      </i>
    ),
  },
  {
    label: <b style={{ color: "orange" }}>ONLY DIRECT SEND TO CORE WALLET</b>,
    description: (
      <i>
        Please note that the minimum amount for TON transactions is 25 TON, and
        the maximum is 500 TON. When sending TON, kindly include the wallet
        referral comment. As you are participating in the CGV IDO first round,
        CGV will be sent immediately upon completion of the transaction.
      </i>
    ),
  },
];

export default function StepModal() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    let timer;
    if (activeStep === stepsModal.length) {
      timer = setTimeout(() => {
        window.location.reload();
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [activeStep]);

  const copyToClipboard = () => {
    const textToCopy = "UQAIbJMmEHCukgngzCCfrP2gwEYMYBG-P-BmOTi-OeT1IAe6";
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("TON Core Wallet address was copied to clipboard: " + textToCopy);
      })
      .catch((error) => {
        console.error("False to save to clipboard:", error);
      });
  };

  return (
    <Box sx={{ maxWidth: 450 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepsModal.map((stepModal, index) => (
          <Step key={stepModal.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last stepModal</Typography>
                ) : null
              }
            >
              {stepModal.label}
            </StepLabel>
            <StepContent>
              <Typography>{stepModal.description}</Typography>
              <Box sx={{ mb: 1 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 2, mr: 1 }}
                  >
                    {index === stepsModal.length - 1 ? "Wallet" : "Continue"}
                  </Button>
                  <Button
                    variant="outlined"
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 2, mr: 1 }}
                    style={{ color: "white" }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === stepsModal.length && (
        <Typography square elevation={1} sx={{ p: 1 }}>
          <i style={{ color: "orange" }}>
            Double-check the recipient address to ensure it is the core admin
            wallet.
          </i>

          <Button
            onClick={copyToClipboard}
            variant="contained"
            color="success"
            sx={{ mt: 2, mr: 1 }}
          >
            Copy Wallet To Clipboard
          </Button>
        </Typography>
      )}
    </Box>
  );
}
