import * as React from "react";
import { useState } from "react";
import USDTlogo from "../../pictures/icon-usdt.svg";
import TONlogo from "../../pictures/ton.svg";
import styled from "@mui/system/styled";
import Grid from "@mui/system/Unstable_Grid";
import Box from "@mui/system/Box";


const data = require("../Modal/Price.json");

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  padding: theme.spacing(2),
  borderRadius: "10px",
  textAlign: "center",
  boxshadow: "2",
}));

export default function Calculator() {
  const [deviseSelect, setDeviseSelect] = useState(0);
  const [priceBeforeChange, setPriceBeforeChange] = useState("");
  const [priceDevise, setPriceDevise] = useState("");
  const [bsb, setBsb] = useState(true);
  const tokenData = data.data[0];
  const poolData = data.included[0];

  const cgvPriceUSD = tokenData.attributes.price_usd;
  console.log("CGV Price (USD):", cgvPriceUSD);

  const token_price_base = poolData.attributes.quote_token_price_base_token;
  console.log("token_price_base (Quote Token):", token_price_base);

  const handleDeviseSelect = (info) => {
    setDeviseSelect(info);
    setPriceDevise("");
    setPriceBeforeChange("");
    setBsb(true);
  };

  const handleChangeDevise = (value) => {
    if (bsb) {
      if (deviseSelect === 1) {
        let tmp = value / cgvPriceUSD;
        tmp = Math.round(tmp * 1e6) / 1e6;
        setPriceDevise(tmp);
      } else {
        let tmp = value * token_price_base;
        tmp = Math.round(tmp * 1e6) / 1e6;
        setPriceDevise(tmp);
      }
    } else {
      let tmp = value * 2.8;
      tmp = Math.round(tmp * 1e6) / 1e6;
      setPriceDevise(tmp);
    }
  };

  const handlePriceBeforeChange = (e) => {
    setPriceBeforeChange(e.target.value);
    handleChangeDevise(e.target.value);
  };
  return (
    <Box sx={{ width: "90%", mb: "15px" }}>
      <p className="titleWallet" style={{ fontSize: "1.5em" }}>
        CALCULATOR MARKET PRICE
      </p>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 1, md: 1 }}>
        <Grid xs={4}>
          <Item onClick={() => {
                handleDeviseSelect(0);
              }}>
            
              <img
                alt="logoCrypto"
                className="logoCrypto1"
                src={!bsb ? USDTlogo : TONlogo}
              ></img>
          </Item>
        </Grid>
        <Grid xs={8}>
          <Item>
            <input
              className="inputMount"
              value={priceBeforeChange}
              onChange={(e) => {
                handlePriceBeforeChange(e);
              }}
              placeholder={bsb ? (deviseSelect === 0 ? "TON" : "USDT") : "TON"}
            ></input>
          </Item>
        </Grid>
        <Grid xs={4}>
          <Item onClick={() => {
                handleDeviseSelect(1);
              }}>
              <img alt="logoCrypto" className="logoCrypto" src={USDTlogo}></img>
          </Item>
        </Grid>
        <Grid xs={8}>
          <Item>
            <input
              className="inputMount"
              placeholder="CGV"
              value={priceDevise}
            ></input>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
