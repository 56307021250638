import React, { useState, useEffect } from "react";
import "./Home.css";
import Logo from "../../pictures/CGVlogo.png";
import Bottom from "../../pictures/bottom.png";
import SplashFgLeft from "../../pictures/splash-fg-left.webp";
import SplashBgLeft from "../../pictures/splash-bg-left.webp";
import SplashFgRight from "../../pictures/splash-fg-right.webp";
import SplashBgRight from "../../pictures/splash-bg-right.webp";
import USDTlogo from "../../pictures/icon-usdt.svg";
import TONlogo from "../../pictures/ton.svg";
import StonFiLogo from "../../pictures/stonfi.svg";
import Calculator from "./Calc";
import StepModal from "../Modal/StepModal";
import TokenNomics from "../Modal/TokenNomics";
import GeckoTer from "../Modal/GeckoTerminal";

const data = require("../Modal/Price.json");
const tokenData = data.data[0];
const poolData = data.included[0];

const cgvPriceUSD = tokenData.attributes.price_usd;
console.log("CGV Price (USD):", cgvPriceUSD);

const token_price_base = poolData.attributes.quote_token_price_base_token;
console.log("token_price_base (Quote Token):", token_price_base);

export default function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [bsb] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="homeContainer">
      <div className="childContainer">
        <img
          alt="leftLogo"
          className="leftLogo"
          src={SplashBgLeft}
          style={{
            transform: `translate(${-scrollPosition * 0.3}px, ${
              -scrollPosition * 0.3
            }px)`,
          }}
        ></img>
        <img
          alt="leftLogo"
          className="leftLogo"
          src={SplashFgLeft}
          style={{
            transform: `translate(${-scrollPosition}px, ${-scrollPosition}px)`,
          }}
        ></img>
        <img
          alt="rightLogo"
          className="rightLogo"
          src={SplashBgRight}
          style={{
            transform: `translate(${scrollPosition * 0.3}px, ${
              -scrollPosition * 0.3
            }px)`,
          }}
        ></img>
        <img
          alt="rightLogo"
          className="rightLogo"
          src={SplashFgRight}
          style={{
            transform: `translate(${scrollPosition}px, ${-scrollPosition}px)`,
          }}
        ></img>
        <div className="logoContainer">
          <img alt="logo" className="imgLogo" src={Logo}></img>
        </div>
        <div className="walletContainer">
        <gecko/>

          <p className="titleWallet">
            “If you can't HODL
            <br />
            💪🏻 you won't be rich 💰”
          </p>
          <p className="textWallet">
            Utilizing the latest advancements of the TON blockchain, CGV Token
            guarantees unparalleled security, scalability, and efficiency in
            every transaction. TON's cutting-edge technology empowers CGV Token
            with lightning-fast transaction speeds and negligible fees, setting
            a new standard in digital asset transactions.
          </p>
          {/* <div className='modelePourcent'>
                        <div className='colorModelePourcent'></div>
                        <p style={{zIndex:'1', align: 'center'}}>USDT level : $646,503.25 / $10,000,000.00</p>
                    </div> */}
          <p className="textWallet">
            Meticulously Crafted Release Schedule: CGV Token's meticulously
            crafted release schedule ensures a gradual introduction of tokens,
            maintaining scarcity as a fundamental driver of its value
            proposition and instilling long-term investor confidence.
          </p>
          <div className="ligne"></div>
          <p className="titleWallet" style={{ fontSize: "1.6em" }}>
            TOKENOMICS
          </p>
          <TokenNomics />
       
          <GeckoTer/>
          <p className="titleWallet" style={{ fontSize: "1.5em" }}>
   
            Current IDO price 25 TON for 9999 CGV
          </p>
          
          <Calculator />
          <div className="ligne"></div>
          <geckoTerminal/>
          <p className="titleWallet" style={{ fontSize: "1.3em" }}>
            If you don't have wallet
          </p>
          <div
            className="modeleButton"
            onClick={() => window.open("https://tonkeeper.com/", "_blank")}
          >
            <span>
              DOWNLOAD{" "}
              <img
                alt="logoCrypto"
                className="logoCrypto"
                src={bsb ? TONlogo : USDTlogo}
              />{" "}
              WALLET
            </span>
          </div>
          <div className="ligne"></div>

          <p className="titleWallet" style={{ fontSize: "1.3em" }}>
            If you want direct buy CGV
          </p>
          <div
            className="modeleButton"
            onClick={() =>
              window.open(
                "https://app.ston.fi/swap?outputCurrency=EQA7Z8us4sajLND4PZPgJb0P9f2Z1Y3BYpfQKd8Aw_myMKlP&chartVisible=true&ft=TON&tt=EQA7Z8us4sajLND4PZPgJb0P9f2Z1Y3BYpfQKd8Aw_myMKlP&chartInterval=1w",
                "_blank"
              )
            }
          >
            <span>
              BUY ON{" "}
              <img alt="logoCrypto" className="logoCrypto1" src={StonFiLogo} />
            </span>
          </div>
          <div className="ligne"></div>
          <p className="titleWallet" style={{ fontSize: "1.3em" }}>
            If you enjoy special offer
          </p>
          <div className="modeleButtonCgv">
            <img alt="logoCrypto" className="logoCrypto" src={Logo} />
            <span>
              <div className="containerDevise1">
                DIRECT BUY FROM CORE TEAM
              </div>
              <StepModal />
            </span>
          </div>
        </div>

        <img alt="bottom" className="imgBottom" src={Bottom}></img>
        <div className="containerStat">
          <div className="leftStat">
            <div className="childStat" id="cgvtotalstake">
              <p className="textStat"> CGV total stake </p>
              <p className="numberStat"> 42,508 </p>
            </div>
            <div className="childStat" id="yourstake">
              <p className="textStat"> % Referral </p>
              <p className="numberStat"> 8% </p>
            </div>
          </div>
          <div className="childStat" id="api">
            <p id="apiText" className="textStat">
              {" "}
              APY{" "}
            </p>
            <p id="apiNumber" className="numberStat">
              {" "}
              1,609%{" "}
            </p>
          </div>
          <div className="rightStat" id="reward">
            <div className="childStat">
              <p className="textStat"> IDO sale on TON</p>
              <p className="numberStat"> 25 TON / 9999 CGV</p>
            </div>
            <div className="childStat" id="stakes">
              <p className="textStat"> Total Round Sale</p>
              <p className="numberStat"> 1 </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
