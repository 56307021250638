import React from 'react';
import "./Header.css"
import Logo from '../../pictures/CGVlogo.png';

// Sử dụng fetchData ở đây
// import { LuMenu } from "react-icons/lu";
// eslint-disable-next-line
export default function Header(){
    
    return (
        <div className='headerContainer'>
            <div  className='childHeader'>
                <div className='leftHeader'>
                    <img className="logoHeader" src={Logo} alt='logo'></img>
                    {/* <nav className='navHeader'>
                        <ul className='ulHeader'> 
                            <li className='liHeader'>UNITE</li>
                            <li className='liHeader'>VALUE</li>
                            <li className='liHeader'>TOKENOMICS</li>
                            <li className='liHeader'>ROAD MAP</li>
                        </ul>
                    </nav> */}
                </div>
                <div className='rightHeader'>
                    {/* <div className='buttonAudit'>
                        <p>CHART</p>
                    </div> */}
                    {/* <div className='buttonBuy'>
                        <p>HOW TO BUY</p>
                    </div>
                    <div className='buttonStaking'>
                        <p>STAKING</p>
                    </div> */}
                </div>
                {/* <LuMenu  className='menuPhone'/> */}
            </div>

        </div>
    )
}